<template>
  <GpPageTemplate rg-page @back="back" :title="$t(`_.title.${title}`)" :structure="structure" app="rg">
    <template #header="{isBackButton}">
      <SubHeader v-if="structure.showSubHeader" :title="$t(`_.title.${title}`)" @back="back" :is-back-button="isBackButton || twoAceBack" />
    </template>
    <template #content="{success, error, loading}">
      <component v-if="load" :is="template" :structure="structure" :rgInfo="rgInfo" :model-info="model" @changeStep="changeStep" :step="step" @success="success" @error="error" @loading="loading" :title="title" :description="description" />
    </template>
  </GpPageTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import GpPageTemplate from '@/views/components/gg-pass/GpPageTemplate.vue';
import Specific from '@shared/types/Specific';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import ResponsibleHeader from '@/views/components/pages/responsible-gaming/template/common/ResponsibleHeader.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import SubHeader from '@/views/components/gg-pass/SubHeader.vue';
import { STEP } from '@/constants/base/my-page';
import { routeNames } from '@/constants/model/responsible-gaming';

export default {
  name: 'ResponsibleGamingProgress',
  lexicon: 'responsibleGaming',
  components: { SubHeader, GpButton, ResponsibleHeader, GpTitle, GpDescription, GpPageTemplate },
  props: {
    config: Specific,
    sitemap: Specific,
    structure: Specific,
  },
  data() {
    return {
      rgInfo: null,
      model: Specific,
      step: STEP.DEFAULT,
      template: null,
      title: null,

      load: false,
    };
  },
  watch: {
    $route: 'update',
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    description() {
      const item = this.$rg.currentPageModel.description;
      let desc = '';
      if (item.hasOwnProperty('verifyFreeze')) {
        if (this.structure.config.verifyFreeze) desc = item.verifyFreeze;
        else desc = item.default;
      } else if (item.hasOwnProperty(this.site)) {
        desc = item[this.site];
      } else desc = item.default;

      return this.$t(`_.${desc}`);
    },
    isShowSubHeader() {
      return this.site !== siteIds.TWOACE;
    },
    twoAceBack() {
      if (this.site === siteIds.TWOACE) {
        return this.step === STEP.INPUT;
      }
      return false;
    }
  },
  methods: {
    back() {
      if (this.$rg.isFirstStep) {
        this.replaceRouteName('RgInfo');
      } else {
        if (this.$rg.isFirstStep && this.$rg.currentStepIndex <= 0) return;
        this.changeStep(this.$rg.stepList[this.$rg.currentStepIndex - 1]);
      }
    },
    changeStep(step) {
      this.$rg.changeStep(step);
      this.step = step;
    },
    async update() {
      this.$store.commit('env/setTheme', 'white');
      this.$rg.changeStep(STEP.DEFAULT);
      this.$rg.updateRouteName(this.$route.name);
      this.$rg.createAction();
      this.step = this.$rg.step;
      this.template = this.$rg.currentComponent;
      this.title = this.$rg.title;

      this.structureReset();

      this.structure.current = await this.rgFetch();
      this.load = true;
    },

    async rgFetch() {
      const r = await this.$rg.fetch();
      if (r?.error) {
        const replace = this.$rg.createErrorProxy(r);
        if (replace) {
          this.replaceRouteName(replace.path, replace.params, replace.query);
          return null;
        }
      }
      return r;
    },

    structureReset() {
      this.structure.showHistory = false;
      this.structure.backRoute = null;
      this.structure.buttonName = 'ok';
      this.structure.showSubHeader = this.$route.name !== routeNames.RgLimitHistory;
      this.structure.config = this.$rg.createConfig(this.config);
      this.structure.current = null;
    },
  },
  async mounted() {
    await this.update();
    this.isDataLoaded = true;
  },
  beforeDestroy() {
    this.data = null;
    this.isDataLoaded = false;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-page] {.flex(); .flex-dc(); flex: 1;
  .desc { .c(black);}
  .submit-button-group { .gap(10); .flex(); .flex-dc();
    @media (@ml-up) {
      .flex-dr();
    }
  }
  .btn {.ph(20);}
}
</style>