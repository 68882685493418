<template>
  <div rg-reponsible-header>
    <div class="rg-header">
      <a :class="['route-name', {'active' : !isActive}]" @click="home">
        <span>{{ $t('responsibleGaming.title.limitSetting').toUpperCase() }}</span>
      </a>
      <a :class="['route-name', {'active' : isActive}]" @click="history">
        <span>{{ $t('responsibleGaming.title.limitHistory').toUpperCase() }}</span>
      </a>
      <a v-if="isGGPNL" :class="['route-name']" @click="openResponsibleGaming">
        <span><img class="nl-image" src="https://ssl.gg-global-cdn.com/game/platform-front/img/widget/responsibleGaming_18.png" height="24" />{{ nlGamingLabel }}</span>
      </a>
    </div>
  </div>
</template>

<script>

import { routeNames } from '@/constants/model/responsible-gaming';
import { rootRoute } from '@/constants/base/responsible-gaming';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';
export default {
  name: 'ResponsibleHeader',
  computed: {
    site: state('env', 'site'),
    isActive() {
      return this.$route.name === routeNames.RgLimitHistory;
      //ssl.gg-global-cdn.com/game/platform-front/img/widget/responsibleGaming_18.png
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    nlGamingLabel() {
      const label = this.$t('responsibleGaming.responsibleGaming');
      return label.toUpperCase();
    }
  },
  methods: {
    back() {
      this.$emit('back');
    },
    async home() {
      await this.replaceRouteName(rootRoute);
    },
    async history() {
      await this.replaceRouteName(routeNames.RgLimitHistory);
    },
    openResponsibleGaming() {
      window.open('https://ggpoker.nl/responsible-gaming', 'blank');
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-reponsible-header] {.bgc(#333333);
  .rg-header { .rel(); .flex();.items-center(); .bgc(#333333); .gap(20); .p(20); .max-w(720); margin: auto; .w(100%);
    @media (max-width: 340px) {
      .ph(20); .gap(10); .items-end();
      .route-name {
        .nl-image {display: none;}
      }
    }
    .route-name {.fs(14); font-weight: 400; text-align: center; .c(#737373); .h(100%); .flex(); .items-center();
      &.active {.c(#fff); font-weight: 500;}
    }

    .nl-image {.pr(10); object-fit: contain;}
  }

  [gp-btn-back] { .abs(); .l(15); .c(white); .mt(0); }
}
</style>
